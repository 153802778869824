<template>
    <div class="full-width pa-5">
        <v-row class="d-flex justify-center align-center my-4">
            <v-icon x-large color="success">mdi-check</v-icon>
        </v-row>
        <h1 class="text-center">Success</h1>
        <p class="text-center">We received your donation.</p>
        <h2 class="secondary--text">Thanks!.</h2>
    </div>
</template>

<script>
    export default {
        created() {
            window.top.postMessage('3DS-authentication-complete');
        }
    }
</script>

<style lang="scss" scoped>

</style>